// @flow
import React, { useContext } from 'react';
import _ from 'lodash';
import type { Action, State } from './types';

export const DEFAULT_STATE = {
  userConflicts: [],
  newConflicts: [],
  updatedConflicts: [],
  submissionHistory: [],
  selectedConflict: null
};

export const ConflictStateContext = React.createContext<State>(DEFAULT_STATE);

export const stateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_CONFLICT': {

      return {
        ...state,
        updatedConflicts: action.payload,
      };
    }

    case 'ADD_CONFLICT': {

      return {
        ...state,
        newConflicts: action.payload,
      };
    }

    case 'SAVE_CONFLICTS': {
      return {
        ...state,
        newConflicts: [],
        updatedConflicts: [],
      };
    }

    case 'LIST_CONFLICTS': {
      return {
        ...state,
        userConflicts: action.payload
      };
    }
    case 'LIST_SUBMISSIONS': {
      return {
        ...state,
        submissionHistory: action.payload
      };
    }

    case 'SELECT_CONFLICT': {
      return {
        ...state,
        selectedConflict: action.payload
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const useConflictState = () => {
  const context = useContext(ConflictStateContext);
  if (context === undefined) {
    throw new Error('useConflictState must be used within a ConflictProvider');
  }
  return context;
};
