
import { useCallback } from 'react';
import makeRequest from './axios';
import Auth from '../Auth';

export const useRequest = () => {
  const auth = new Auth();
  // memoized the function, as otherwise if the hook is used inside a useEffect, it will lead to an infinite loop
  const memoizedFn = useCallback(
    async (request) => {
      const accessToken = auth.getToken();
      return makeRequest({
        ...request,
        headers: {
          ...request.headers,
          // Add the Authorization header to the existing headers
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    []
  );
  return {
    requestMaker: memoizedFn,
  };
};

export default useRequest;
