import React, {useEffect} from 'react';

import { Button, Tooltip , Breadcrumb, Table,Tag, PageHeader, Alert } from 'antd';
import styles from './index.module.css';
import globalStyles from '../common.module.css';
import Auth from '../../Auth';
import { navigate } from '@reach/router';
import moment from 'moment'
import { useConflictState, useConflict } from '../../context/conflict/index';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';


function ListConflicts() {
  let { listConflicts, selectConflict} = useConflict();
  let { newConflicts, userConflicts, updatedConflicts, submissionHistory  } = useConflictState();   
  const auth = new Auth();
  const currentUser = auth.currentUser();

  useEffect(() => {    
    listConflicts(currentUser.id);
  }, []);

  const columns = [
    {
      title: 'Organisation / Individual',
      dataIndex: 'relationDetails',
      key: 'relationDetails',
      render: (col, row) => {
        if(row.id)
        { 
          let i = updatedConflicts.findIndex((e) => {
            return e.id === row.id;
          });
          if(i > -1)
          {
            return <React.Fragment>
          <Tooltip title="This record is not saved"><ExclamationCircleOutlined style={{color: 'red'}}/></Tooltip> {col}</React.Fragment>
          }
          return col;
        }
        else
        {
          return <React.Fragment>
          <Tooltip title="This record is not saved"><ExclamationCircleOutlined style={{color: 'red'}}/></Tooltip> {col}</React.Fragment>
        }
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'creationDate',
      key: 'createdDate',
      render: createdDate => {
        return moment(createdDate).format('MMM Do, YYYY, HH:mm:ss')
      }
    },
    {
      title: 'Involvement',
      dataIndex: 'involvementStatus',
      key: 'involvementStatus',
      render: (involvement, row) => {
      return <React.Fragment><Tag color={involvement === 'Current' ? 'green' : 'red'} key={involvement}>{involvement} {involvement === 'Expired' && `(${moment(row.involvementEndDate).format('MMM Do, YYYY')})`}</Tag></React.Fragment>
      }
    },
    {
      title: 'Last Modified',
      dataIndex: 'modifiedDate',
      key: 'lastModified',
      render: lastModified => {
        return moment(lastModified).format('MMM Do, YYYY, HH:mm:ss')
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (item, row) => {
        return <a onClick={() => {
          selectConflict(row)
          navigate('/edit')
        }}>View / Edit</a>
      }
    },
  ];

 let combinedUpdates = newConflicts.concat(updatedConflicts);

  const getTableData = () => {

    // find index of matching updates   
    let combined = newConflicts.concat(userConflicts);

    return combined.map((row) => {
      let idx = updatedConflicts.findIndex((x) => {
        return x.id === row.id
      })
      if(idx > -1)
      {
          return updatedConflicts[idx];
      }
      return row;
    })
    
  }

  return (
    <React.Fragment>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item onClick={() => {
          navigate('/')
        }}>Home</Breadcrumb.Item>
      </Breadcrumb>

      {submissionHistory.length > 0 && <React.Fragment><Alert message={`Your last submission was ${moment(submissionHistory[0].submittedDate).fromNow()} with ${submissionHistory[0].submissionUpdates > 0 ? ` ${submissionHistory[0].submissionUpdates} update(s).` : `no reported changes.` } `} type="info" showIcon /><br/></React.Fragment>}

      <div className={globalStyles.container}>
        
      <h1 className={globalStyles.h1}>Welcome {currentUser.firstName},</h1>     
      <p>In order to maintain the highest standard of integrity, all employees, partners, contractors and other associates must declare any outside interests that they, or members of their immediate family and household, may have that could conflict with, have the potential to conflict with, or could be perceived to conflict with the interests of DoMoreIT.</p>           
      <p>Click on the relevant button below to either add details of potential conflicts, or confirm you have nothing to declare.  Examples of potential conflicts are shown at the bottom of the page.</p>
      <p>

<strong>Please note:</strong>
<ul>
  <li>Declarations are required for you, your immediate family (partner / spouse / parent / sibling / child(ren) / dependants) and members of your household</li>
  <li>You should complete and submit a declaration even if you consider that you have no potential conflicts</li>
  <li>Declarations are required for all current relationships and those that have expired in the last eight years</li>
  <li>If you are unsure whether a relationship or association represents a conflict, please contact COI@domoreit.co.uk</li>
</ul>
</p>
      {combinedUpdates.length > 0 && <React.Fragment><Alert showIcon message={`You have ${combinedUpdates.length} unsaved conflict(s).  Please confirm submission to save progress.`} type="warning" /></React.Fragment>}

      <PageHeader
      style={{borderBottom: '1px solid #ddd', marginBottom: '20px', padding: '20px 0px'}}
      ghost={false}
      title="Your Conflicts"
      subTitle="View / Edit your submitted conflicts"
      extra={[
        <Button className={styles.submitConflictBtn} key="1"  onClick={() => 
          navigate('/confirm')
      
      }>{combinedUpdates.length > 0 ? 'Confirm Submissions' : 'I have no conflicts of interest to declare'}</Button>,
        <Button className={styles.addConflictBtn} key="2" type="primary" onClick={() => navigate('/add')}>
          Add New Conflict
        </Button>,
      ]}
    ></PageHeader>   
 
    <Table dataSource={getTableData()} columns={columns} />
    <br/>
<p>
<strong>Examples of conflicts that should be declared:</strong>
<ul>
  <li>Involvement in the participation or administration of another public body</li>
  <li>If you are related to, or involved in, the personal affairs of an employee related to another public body</li>
  <li>Involvement in the daily activities of another public body</li>
  <li>Engagement in business with, having a financial interest in or a personal stake in another public body</li>
  <li>Involvement in activities which could draw, or are likely to draw, personal and/or professional gain</li>
  <li>Involvement in activities which could appear to have private or personal interests within a public body</li>
</ul>
</p>      
      </div>    
    </React.Fragment>   
  );
}

export default ListConflicts;
