import React, {useState} from 'react';
import styles from './index.module.css';
import { useFormik, FormikConsumer, validateYupSchema } from 'formik';
import { navigate } from '@reach/router';
import moment from 'moment'

import { Form, Button, Input, Radio, DatePicker } from 'antd';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  conflictType: Yup.string().required('This field is required.'),
  relationDetails: Yup.string().required('This field is required.'),
  conflictTypeOther: Yup.string().when('conflictType', { is: 'Other', then: Yup.string().required('This field is required.'), otherwise: Yup.string().nullable()}),
  involvement: Yup.string().required('This field is required.'),
  involvementStatus: Yup.string().required('This field is required.'),    
  involvementEndDate: Yup.date().when('involvementStatus', { is: 'Expired', then: Yup.date().required('This field is required.'), otherwise: Yup.date().nullable()}).nullable(),
  relation: Yup.string().required('This field is required.'),
  verified: Yup.string().required('This field is required.'),
});


function ConflictForm({ data, onSubmit, onEdit, mode }) {
  const [showOther, toggleOther] = useState(data?.conflictType  === 'Other')
  const [showDate, toggleDate] = useState(data?.involvementStatus  === 'Expired')
  const [showRelation, toggleRelation] = useState(data?.relationshipDetails  === 'You')

  const formik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      conflictType: data?.conflictType,
      relationDetails: data?.relationDetails,
      conflictTypeOther: data?.conflictTypeOther,
      involvement: data?.involvement,
      involvementEndDate: data?.involvementEndDate,
      involvementStatus : data?.involvementStatus,
      relation: data?.relation,
      relationshipDetails: data?.relationshipDetails,
      verified: data?.verified
    },
    onSubmit: (values) => {    
      
        if(values.conflictType !== 'Other') 
        {
          values.conflictTypeOther = null;
        }
        if(values.involvementStatus !== 'Expired')
        {
          values.involvementEndDate = null;
        }
        onSubmit(values);
    },
  });

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  if(!data && mode === 'Edit')
  {
    navigate('/')
  }


  return (
    <div className={styles.wrapper}>
      <Form layout="vertical" size="large" onFinish={formik.handleSubmit}>
        <label className={styles.label}>
          1. Which of the following does the conflict relate to?
        </label>
        <Form.Item style={{ textAlign: 'left' }} >
          <Radio.Group  
            onChange={(e) => {
              toggleOther(e.target.value === 'Other');
              formik.handleChange(e);
            }}
            id="conflictType"
            name="conflictType"
            value={formik.values.conflictType}
          >
            <Radio
              style={radioStyle}
              value="Other Related Organisations"
              style={{ whiteSpace: 'normal' }}
            >
              Membership of, or involvement with other related public bodies
            </Radio>
            <Radio style={radioStyle} value="Commercial Interests">
              Commercial interests (for example in companies owning, operating
              or providing facilities or services)
            </Radio>
            <Radio style={radioStyle} value="Other">
              Other
            </Radio>
          </Radio.Group>
          {formik.errors.conflictType ? <div className={styles.error}>{formik.errors.conflictType}</div> : null}
        </Form.Item>
        
        {showOther && (
          <React.Fragment>
            <label className={styles.label}>
            If other, please detail below.
            </label>
            <Form.Item style={{ textAlign: 'left' }}>
              <Input.TextArea
                placeholder=""
                id="conflictTypeOther"
                name="conflictTypeOther"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.conflictTypeOther}
              />
            </Form.Item>
          </React.Fragment>
        )}
        <label className={styles.label}>
        2.  What is the name of the organisation or individual to which the
          potential conflict relates?
        </label>
        <Form.Item style={{ textAlign: 'left' }}>
          <Input.TextArea
            placeholder="Required"
            id="relationDetails"
            name="relationDetails"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.relationDetails}
          />
          {formik.errors.relationDetails  ? <div className={styles.error}>{formik.errors.relationDetails}</div> : null}
        </Form.Item>
        <label className={styles.label}>
        3. Please outline the nature of your involvement with the public body. For example
          provide an overview of your role, memberships, commercial
          relationships, contact with employees.
        </label>
        <Form.Item style={{ textAlign: 'left' }}>
          <Input.TextArea
            placeholder="Required"
            id="involvement"
            name="involvement"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.involvement}
          />
          {formik.errors.involvement  ? <div className={styles.error}>{formik.errors.involvement}</div> : null}
        </Form.Item>
        <label className={styles.label}>
        4. Is the involvement with the public body / individual current or has
          it ended? Please include details of activities which have expired in
          the last eight years.
        </label>
        <Form.Item style={{ textAlign: 'left' }}>
          <Radio.Group
            onChange={(e) => {
              toggleDate(e.target.value === 'Expired');
              formik.handleChange(e);
            }}
            id="involvementStatus"
            name="involvementStatus"
            value={formik.values.involvementStatus}
          >
            <Radio style={radioStyle} value="Current">
              Current
            </Radio>
            <Radio style={radioStyle} value="Expired">
              Expired
            </Radio>
          </Radio.Group>
          {formik.errors.involvementStatus ? <div className={styles.error}>{formik.errors.involvementStatus}</div> : null}
        </Form.Item>
        {showDate && (
          <React.Fragment>
            <label className={styles.label}>
            When did the involvement with the public body / individual end?
            </label>
            <Form.Item style={{ textAlign: 'left' }}>
              <DatePicker
                onChange={(date, dateString) => {
                  formik.setFieldValue('involvementEndDate', date);
                }}
                id="involvementEndDate"
                name="involvementEndDate"
                disabledDate={(current) =>{return current>moment();}}
                value={formik.values.involvementEndDate ? moment(formik.values.involvementEndDate): null}
              />
              
              
              {formik.errors.involvementEndDate  ? <div className={styles.error}>{formik.errors.involvementEndDate}</div> : null}
            </Form.Item>
          </React.Fragment>
        )}

        <label className={styles.label}>
        5. Please indicate who the potential conflict relates to;
        </label>
        <Form.Item style={{ textAlign: 'left' }}>
          <Radio.Group
            onChange={(e) => {
              toggleRelation(e.target.value === 'You');
              formik.handleChange(e);
            }}
            id="relation"
            name="relation"
            value={formik.values.relation}
          >
            <Radio style={radioStyle} value="Self">
              You
            </Radio>
            <Radio style={radioStyle} value="Partner / spouse">
              Partner / spouse
            </Radio>
            <Radio style={radioStyle} value="Immediate family member">
              Immediate family member (parent, sibling, child, dependant)
            </Radio>
            <Radio style={radioStyle} value="Member of the household">
              Member of your household (non-family member)
            </Radio>
          </Radio.Group>
              {formik.errors.relation ? <div className={styles.error}>{formik.errors.relationDetails}</div> : null}
        </Form.Item>
        <label className={styles.label}>
        I declare that to the best of my knowledge and belief, the information
          provided is correct and complete. I agree to notify DoMoreIT as soon as
          practically possible if any changes take place and potential conflicts
          arise.
        </label>
        <Form.Item style={{ textAlign: 'left' }}>
          <Radio.Group
            onChange={formik.handleChange}
            id="verified"
            name="verified"
            value={formik.values.verified}
          >
            <Radio style={radioStyle} value="Agree">
              Agree
            </Radio>
            <Radio style={radioStyle} value="Disagree">
              Disagree
            </Radio>
          </Radio.Group>
              {formik.errors.verified ? <div className={styles.error}>{formik.errors.verified}</div> : null}
        </Form.Item>
        <Button className={styles.addConflictBtn} type="primary" disabled={!(formik.isValid)} htmlType="submit" size="large" >
              {mode === 'Edit' ? 'Save Changes' : 'Add Conflict'}
        </Button>
      </Form>
    </div>
  );
}

export default ConflictForm;
