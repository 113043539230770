
import authentication from 'react-azure-b2c';
import decodeJWT from 'jwt-decode'; 

class Auth {
  isLoggedIn() {
    if (authentication.getAccessToken()) {
      return true;
    }

    return false;
  }

  logout() {
    authentication.signOut();
  }

  getToken() {
    return authentication.getAccessToken().accessToken;
  }

  currentUser() {
    const decoded = decodeJWT(authentication.getAccessToken().accessToken);

    const userObj = {
      id: decoded.oid,
      name: decoded.name,
      type: decoded.extension_Type,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      email: decoded?.emails[0]
    }

    return userObj;
  }
}

export default Auth;