import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import authentication from 'react-azure-b2c';

try {
  authentication.initialize({
    instance: process.env.REACT_APP_AZURE_B2C_INSTANCE,
    tenant: process.env.REACT_APP_AZURE_B2C_TENANT,
    signInPolicy: process.env.REACT_APP_AZURE_B2C_SIGNINPOLICY,
    clientId: process.env.REACT_APP_AZURE_B2C_CLIENTID,
    cacheLocation: process.env.REACT_APP_AZURE_B2C_CACHELOCATION,
    scopes: process.env.REACT_APP_AZURE_B2C_SCOPES.split(','),
    redirectUri: process.env.REACT_APP_AZURE_B2C_REDIRECTURL,
    postLogoutRedirectUri: window.location.origin,
  });

  authentication.run(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
} catch (ex) {
  console.log(ex.message);
  //Of fails, the code redirect to your own page with the error param
  if (ex.message.indexOf('Hash does not contain state') > -1) {
    window.location.href = window.location.origin + '';
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
