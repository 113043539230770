import React, { useState, useCallback, useRef } from 'react';

import { Breadcrumb, PageHeader, Button } from 'antd';
import styles from './index.module.css';
import globalStyles from '../common.module.css';
import ConflictForm from '../../forms/conflictForm'
import Auth from '../../Auth';
import { navigate } from '@reach/router';
import { useConflict } from '../../context/conflict/index';
import { ConflictRequestFactory } from '../../shared/requestFactory';
import { useRequest } from '../../shared/hooks';

function AddConflict() {
  const { requestMaker } = useRequest(); // Custom Hook
  const auth = new Auth();
  const currentUser = auth.currentUser();  
  let {
    addConflict
  } = useConflict();

  return (
    <React.Fragment>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item onClick={() => {
          navigate('/')
        }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Add Conflict</Breadcrumb.Item>
      </Breadcrumb>
      <div className={globalStyles.container}>
      <PageHeader
      
      style={{borderBottom: '1px solid #ddd', marginBottom: '20px'}}
      onBack={() => navigate(-1)}
      title="Add New Conflict"
    ></PageHeader>
        <ConflictForm mode="Add" onSubmit={(data) => {
          addConflict(data);
          navigate('/');
        }} />
      </div>
    </React.Fragment>
  );
}

export default AddConflict;
