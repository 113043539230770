import React, { useState, useCallback, useRef } from 'react';

import { Button, Row, Col, Breadcrumb, message, PageHeader } from 'antd';
import styles from './index.module.css';
import globalStyles from '../common.module.css';
import Auth from '../../Auth';
import { navigate } from '@reach/router';
import { ConflictRequestFactory } from '../../shared/requestFactory';
import { useRequest } from '../../shared/hooks';
import { useConflictState, useConflict } from '../../context/conflict/index';

function Confirmation() {
  const { requestMaker } = useRequest(); //
  const { newConflicts, updatedConflicts } = useConflictState();
  const { saveConflicts, listSubmissions } = useConflict();

  const auth = new Auth();
  const currentUser = auth.currentUser();
  return (
    <React.Fragment>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item
          onClick={() => {
            navigate('/');
          }}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item>Confirmation</Breadcrumb.Item>
      </Breadcrumb>
      <div className={globalStyles.container}>
        <PageHeader
          style={{ borderBottom: '1px solid #ddd', marginBottom: '20px' }}
          ghost={false}
          onBack={() => navigate(-1)}
          title="Conflict of Interest Submission Confirmation"
        ></PageHeader>
        <label className={globalStyles.label}>
          {newConflicts.length === 0
            ? 'I declare that to the best of my knowledge and belief, the information provided is correct and complete.  I agree to notify DoMoreIT as soon as practically possible if any changes take place and any potential conflicts arise.'
            : 'By clicking on submit, you are confirming that you have provided details of all potential conflicts to the best of your knowledge.'}
        </label>
        <br />
        <br />
        <Row gutter={16}>
          <Col flex={1}>
            <div
              className={styles.largeBtn}
              onClick={() => {
                message.loading('Saving...', 1).then(() => {
                  let obj = {
                    userId: currentUser.id,
                    userEmail: currentUser.email,
                    userType: currentUser.type,
                    userForename: currentUser.firstName,
                    userSurname: currentUser.lastName,
                    newConflicts: newConflicts,
                    updatedConflicts: updatedConflicts,
                  };

                  saveConflicts(obj, function () {
                    listSubmissions(currentUser.id);
                    navigate('/');
                    message.success('Save Complete');
                  });
                });
              }}
            >
              Submit
            </div>
          </Col>
        </Row>{' '}
        <Row gutter={16}>
          <Col flex={1}>
            <div className={styles.btnText} onClick={() => navigate('/')}>
              Back
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default Confirmation;
