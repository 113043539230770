import React, { Suspense } from 'react';
import { Redirect, Router } from '@reach/router';

// Private Routes
import AddConflict from '../pages/addConflict';
import Confirmation from '../pages/confirmation';
import EditConflict from '../pages/editConflict';
import ListConflict from '../pages/listConflicts';

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router style={{ height: '100%' }}>
        <ListConflict path="/" />
        <AddConflict path="/add" />
        <Confirmation path="/confirm" />
        <EditConflict path="/edit" />
        <Redirect noThrow from="/*" to="/" default />
      </Router>
    </Suspense>
  );
};

export default Routes;
