// @flow
import { ComponentType, Node } from 'react';
import React, { useReducer } from 'react';

import { ConflictDispatchContext, useConflict } from './dispatch';
import {
  ConflictStateContext,
  DEFAULT_STATE,
  stateReducer,
  useConflictState,
} from './state';

type ConflictProviderProps = { children: Node };

const ConflictProvider = ({ children }: ConflictProviderProps) => {
  const [state, dispatch] = useReducer(stateReducer, DEFAULT_STATE);

  return (
    <ConflictStateContext.Provider value={state}>
      <ConflictDispatchContext.Provider value={dispatch}>
        {children}
      </ConflictDispatchContext.Provider>
    </ConflictStateContext.Provider>
  );
};

const withConflict = (component: ComponentType<any>) => (props: any) =>
  React.createElement(component, props);

export { ConflictProvider, withConflict, useConflict, useConflictState };
