

import './App.css';
import Auth from './Auth';
import Layout from './components/layout/root.js'
import { ConflictProvider } from './context/conflict/index.js';

function App() {

  const auth = new Auth();
  if (auth.isLoggedIn()) {
    
    return <ConflictProvider><Layout /></ConflictProvider>;
  }
}

export default App;
