import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'content-type': 'application/json',
    'access-control-allow-origin': '*',
  },
  mode: 'no-cors',
});

export default instance;
