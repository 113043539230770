// Get metadata for all queues creaetd by the current user
export const addUpdateConflict = (conflict) => ({
  method: 'POST',
  url: '/COI_Submit',
  data: conflict,
});

// Get metadata for all queues creaetd by the current user
export const listConflicts = (userId) => ({
  method: 'POST',
  url: '/COI_List',
  data: {userId: userId},
});

// Get metadata for all queues creaetd by the current user
export const listSubmissions = (userId) => ({
  method: 'POST',
  url: '/COI_Submissions',
  data: {userId: userId},
});