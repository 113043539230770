// @flow
import React, { useCallback, useContext } from 'react';

import type { Dispatch } from './types';
import _ from 'lodash';
import { useConflictState } from './state';
import { ConflictRequestFactory } from '../../shared/requestFactory';
import { useRequest } from '../../shared/hooks';
import { v4 as uuidv4 } from 'uuid'


export const ConflictDispatchContext = React.createContext<Dispatch>(() => {});
export const useConflict = () => {
  
  const { requestMaker } = useRequest(); //
  const dispatch = useContext(ConflictDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useConflict must be used within a ConflictProvider');
  }

  const state = useConflictState();

  const updateConflict  = useCallback(
    (data: ObjectHTMLAttributes) => {
      var updatedConflicts = [...state.updatedConflicts]
      var newConflicts = [...state.newConflicts]
    
      if(state.selectedConflict.id)
      {
        data.id = state.selectedConflict.id;          
      }
      if(state.selectedConflict.conflictId)
      {
        data.conflictId = state.selectedConflict.conflictId;
      }
      data.createdDate = new Date();    
      data.state = "pending";
      data.lastModified = new Date();

      // DOES THE ITEM ALREADY EXIST IN THE UPDATED LIST..IF SO REPLACE
      if(data.conflictId) //If updating NEW then adjust NEW list
      {
        let newIndex = newConflicts.findIndex((x) => {
          return x.conflictId === data.conflictId;
        })
        if(newIndex > -1)
        {
          newConflicts[newIndex] = data;
        }else{
          newConflicts.push(data);        
        }  
        dispatch({ type: 'ADD_CONFLICT', payload: newConflicts });

      }else{
        let updatedIndex = updatedConflicts.findIndex((x) => {
          return x.id === data.id;
        })

        if(updatedIndex > -1)
        {
          updatedConflicts[updatedIndex] = data;
        }else{
          updatedConflicts.push(data);        
        }        
        
        dispatch({ type: 'UPDATE_CONFLICT', payload: updatedConflicts });
      }

    },
    [dispatch, state]
  );

  const addConflict = useCallback(
    (data: ObjectHTMLAttributes) => {
      // getConflict
      data.createdDate = new Date();
      data.conflictId =  uuidv4();
      data.state = "pending";
      data.lastModified = new Date();
      var conflicts = [...state.newConflicts]
      conflicts.push(data);

      dispatch({ type: 'ADD_CONFLICT', payload: conflicts });
    },
    [dispatch, state]
  );

  const listConflicts = useCallback((data: ObjectHTMLAttributes) => {

    requestMaker(ConflictRequestFactory.listConflicts(data)).then(
      (response) => {
        dispatch({ type: 'LIST_CONFLICTS', payload: response.data });
      }
    );
  });


  const listSubmissions = useCallback((data: ObjectHTMLAttributes) => {
    requestMaker(ConflictRequestFactory.listSubmissions(data)).then(
      (response) => {
        dispatch({ type: 'LIST_SUBMISSIONS', payload: response.data });
      }
    );
  });


  const selectConflict = useCallback((data: ObjectHTMLAttributes) => {
    var updatedConflicts = [...state.updatedConflicts]
    var newConflicts = [...state.newConflicts]
    if(data.conflictId) //If updating NEW then adjust NEW list
    {
      let newIndex = newConflicts.findIndex((x) => {
        return x.conflictId === data.conflictId;
      })  
      dispatch({ type: 'SELECT_CONFLICT', payload: newConflicts[newIndex] });
    }else{
      let updatedIndex = updatedConflicts.findIndex((x) => {
        return x.id === data.id;
      })         
      if(updatedIndex > -1){
          dispatch({ type: 'SELECT_CONFLICT', payload: updatedConflicts[updatedIndex] });
      }else{
        dispatch({ type: 'SELECT_CONFLICT', payload: data });
      }
    }

  },
  [dispatch, state]);

  const saveConflicts = useCallback(
    (data: ObjectHTMLAttributes, callback) => {
      // getConflict
              
      requestMaker(ConflictRequestFactory.addUpdateConflict(data)).then(
        (response) => {
          if(callback)
          {            
            var newConflicts = [...state.newConflicts]
            var userConflicts = [...state.userConflicts]
            let newArry = userConflicts.concat(newConflicts);

            dispatch({ type: 'SAVE_CONFLICTS', payload: newArry });
            callback();
          }
        }
     );

    },
    [dispatch, state]

     

  );


  return {
    addConflict,
    saveConflicts,
    listConflicts,
    selectConflict,
    updateConflict,
    listSubmissions
  };
};
